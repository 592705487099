import Vue from 'vue';
var CryptoJS = require("crypto-js");

class DataAPI {

    constructor() {
        this.URL = "https://email-visualisation.accen.me/api";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    sendinBlue_user_login(username, password, callback) {
        let datas = { username, password }
        Vue.$http.post(this.URL + '/v1/sendinBlue_user_login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        })
    }

    sendinBlue_get_emails_list(email, callback) {
        let datas = { email }
        Vue.$http.post(this.URL + '/v1/sendinBlue_get_emails_list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        })
    }

}
export default new DataAPI()