<template>
  <v-row v-if="show" justify="center" class="mx-0">
    <v-col
      v-if="!isConnected"
      cols="11"
      md="4"
      align="center"
      class="pa-0"
      style="margin-top: 20vh"
    >
      <v-card>
        <div>
          <div
            style="
              border-bottom: solid 1px lightgrey;
              width: 90%;
              padding: 20px 0px;
            "
          >
            <h1>Connexion</h1>
            <div>EMAIL VISUALISATION</div>
          </div>
          <v-card-text>
            <v-form>
              <v-col
                cols="11"
                md="12"
                v-for="(elem, idx) in loginForm"
                :key="idx"
                class="py-2"
              >
                <v-text-field
                  outlined
                  dense
                  :append-icon="elem.icon"
                  :name="elem.label"
                  :placeholder="elem.label"
                  :type="elem.type"
                  v-model="loginDataForm[elem.key]"
                  hide-details
                  :error="elem.error"
                >
                </v-text-field>
              </v-col>
            </v-form>
          </v-card-text>
          <div v-if="creatMsg" class="mb-2">
            <v-chip :color="creatMsg.color" style="color: white">
              {{ creatMsg.value }}
            </v-chip>
          </div>
          <v-col class="pt-0">
            <v-btn color="primary" @click="login()"> Connexion </v-btn>
          </v-col>
        </div>
      </v-card>
    </v-col>
    <v-col v-else>
      <emails-vue @logout="logout($event)" :emailList="emailsTab"></emails-vue>
    </v-col>
  </v-row>
</template>

<script>
import engine from "@/data/dataApi";
import emailsVue from "@/components/emails-tab.vue";

export default {
  data() {
    return {
      show: true,
      creatMsg: undefined,
      validateColor: "#4CAF50",
      errorColor: "#EF5350",
      isConnected: false,
      loginDataForm: {},
      dataForm: {},
      emailsTab: [],
    };
  },
  components: {
    emailsVue,
  },
  computed: {
    form() {
      return [
        {
          label: "Nom d'utilisateur",
          type: "text",
          key: "user_name",
          error: false,
        },
        {
          label: "Mail",
          type: "email",
          key: "user_email",
          error: false,
        },
        {
          label: "Mot de passe",
          type: "password",
          key: "pwd1",
          error: false,
        },
        {
          label: "Mot de passe",
          type: "password",
          key: "pwd2",
          error: false,
        },
      ];
    },
    loginForm() {
      return [
        {
          icon: "mdi-account",
          label: "Mail",
          type: "email",
          key: "user_email",
          error: false,
        },
        {
          icon: "mdi-lock",
          label: "Mot de passe",
          type: "password",
          key: "user_password",
          error: false,
        },
      ];
    },
  },
  methods: {
    logout(){
      this.emailsTab = []
      this.isConnected = false
    },
    login() {
      if (
        !this.loginDataForm["user_password"] ||
        this.loginDataForm["user_password"].length === 0
      ) {
        this.loginForm[
          this.loginForm.findIndex((val) => val.key === "user_password")
        ].error = true;
      } else {
        this.loginForm[
          this.loginForm.findIndex((val) => val.key === "user_password")
        ].error = false;
      }
      if (
        !this.loginDataForm["user_email"] ||
        this.loginDataForm["user_email"].length === 0
      ) {
        this.loginForm[
          this.loginForm.findIndex((val) => val.key === "user_email")
        ].error = true;
      } else {
        this.loginForm[
          this.loginForm.findIndex((val) => val.key === "user_email")
        ].error = false;
      }
      let error = this.loginForm.findIndex((val) => val.error === true);
      let that = this;
      if (error === -1) {
        engine.sendinBlue_user_login(
          this.loginDataForm["user_email"],
          this.loginDataForm["user_password"],
          function callback(data) {
            if (data.code === 0) {
              let obj = data.data;
              that.emailsTab = obj.MAILS.split("|");
              that.isConnected = true;
            } else {
              that.creatMsg = {
                value: "Identifiant ou mot de passe incorecte",
                color: that.errorColor,
              };
              setTimeout(function () {
                that.creatMsg = undefined;
              }, 5000);
            }
          }
        );
      } else {
        that.creatMsg = {
          value: "Tous les champs sont obligatoire",
          color: that.errorColor,
        };
        setTimeout(function () {
          that.creatMsg = undefined;
        }, 5000);
      }
    },
    showAlertmsg(msg) {
      let that = this;
      this.creatMsg = {
        value: msg,
        color: this.errorColor,
      };
      setTimeout(function () {
        that.creatMsg = undefined;
      }, 5000);
    },
    isEmailValid(email) {
      let reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return email == "" ? "" : reg.test(email) ? true : false;
    },
  },
};
</script>

<style></style>
