<template>
  <div>
    <!-- <v-dialog v-if="emailDetailData" v-model="isEmailDetailDataOpen" width="1200">
      <v-card>
        <v-col>
          <v-col style="font-weight: bold; font-size: 20px">
            Sujet : {{ emailDetailData.subject }}
          </v-col>
          <v-col>
            <v-card class="pa-2" outlined style="overflow-x: auto; overflow-y: auto; max-height: 600px">
              <v-col>
                <div v-html="emailDetailData.body"></div>
              </v-col>
            </v-card>
          </v-col>
          <v-row class="mx-1">
            <v-col class="px-0" v-for="(value, key) in emailDetailData.events" :key="key" align="center">
              <v-card outlined class="ma-2">
                <v-col class="pa-1">
                  {{ stateTranslation[key] }}
                  <div v-for="elem in value" :key="elem">
                    {{ $moment(elem).format("DD/MM/YYYY HH:mm:ss") }}
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-col align="center">
            <v-btn color="red" style="color: white" @click="isEmailDetailDataOpen = false">Fermer</v-btn>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog> -->
    <v-dialog v-if="emailSendingBlueDetailData" v-model="isEmailDetailDataOpen" max-width="600px" min-width="90%">
      <v-card>
        <v-col>
          <v-col style="font-weight: bold; font-size: 20px">
            Sujet : {{ emailSendingBlueDetailData.subject }}
          </v-col>
          <v-divider></v-divider>
          <v-col>
            <v-card class="" flat style="overflow-x: auto; overflow-y: auto; max-height: 600px">
              <v-col>
                <div v-html="emailSendingBlueDetailData.body"></div>
              </v-col>
            </v-card>
          </v-col>
          <v-row class="mx-1">
            <v-col class="px-0" v-for="(value, key) in emailSendingBlueDetailData.events" :key="key" align="center">
              <v-card class="ma-2" flat style="border: solid 1px lightgrey">
                <v-col class="pa-1">
                  {{ stateTranslation[key] }}
                  <div v-for="elem in value" :key="elem">
                    {{ getFormatedDate(elem) }}
                    <!-- {{ moment(elem).format("DD/MM/YYYY HH:mm:ss") }} -->
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-col align="center" class="pa-0">
            <v-btn class="mt-2" style="background-color: red; font-weight: bold" color="white"
              @click="isEmailDetailDataOpen = false">Fermer</v-btn>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-if="emailmailShipDetailData" v-model="isEmailDetailDataOpen" max-width="600px" min-width="90%">
      <v-card flat>
        <v-col>
          <v-col style="font-weight: bold; font-size: 20px">
            Sujet : {{ emailmailShipDetailData.subject }}
          </v-col>
          <v-divider></v-divider>
          <v-col>
            <v-card class="" flat style="overflow-x: auto; overflow-y: auto; max-height: 600px">
              <v-col>
                <div v-html="emailmailShipDetailData.html"></div>
              </v-col>
            </v-card>
          </v-col>
          <!--<v-col>
            <v-card class="" variant="flat" style="overflow-x: auto; overflow-y: auto; max-height: 600px">
              <v-col>
                <div v-html="emailSendingBlueDetailData.body"></div>
              </v-col>
            </v-card>
          </v-col>
          <v-row class="mx-1">
            <v-col class="px-0" v-for="(value, key) in emailSendingBlueDetailData.events" :key="key" align="center">
              <v-card class="ma-2" variant="plain" style="border: solid 1px lightgrey">
                <v-col class="pa-1">
                  {{ stateTranslation[key] }}
                  <div v-for="elem in value" :key="elem">
                    {{ getFormatedDate(elem) }}
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-col align="center" class="pa-0">
            <v-btn class="mt-2" style="background-color: red; font-weight: bold" color="white" variant="outlined"
              @click="isEmailDetailDataOpen = false">Fermer</v-btn>
          </v-col>-->
        </v-col>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="mx-2 mt-2" align="center">
        <v-card class="mb-2 pa-2" outlined>
          <div style="position:absolute;right:20px;">
            <v-icon color="red" style="cursor:pointer;" @click="logout">mdi-logout</v-icon>
          </div>
          <h1 class="mb-0">EMAIL HISTORIQUE</h1>
          <h5 class="ml-1">Séléctionner une adresse mail</h5>
          <v-autocomplete class="px-2" outlined dense v-model="selectedEmail" :items="emailList"></v-autocomplete>
        </v-card>
        <v-card v-if="emailsList" outlined>
          <v-data-table :headers="headers" :items="emailsListFiltered" :items-per-page="itemsPerPage" :page.sync="page"
            :custom-filter="filterOnlyCapsText" :search="search" disable-sort fixed-header height="59vh"
            hide-default-footer @page-count="pageCount = $event">
            <template v-slot:top>
              <div class="mx-4 pt-4">
                <v-col>
                  <v-row>
                    <v-col class="px-0">
                      <v-btn color="primary" elevation="0" style="width: 100%" @click="type = 'send'"
                        :text="type != 'send'">Envoyé</v-btn>
                    </v-col>
                    <v-col class="px0-0">
                      <v-btn color="primary" elevation="0" style="width: 100%" @click="type = 'receive'"
                        :text="type != 'receive'">Recus</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-row class="mt-1">
                  <v-col>
                    <v-text-field outlined dense v-model="tmpSearch" label="Recherche" clearable
                      v-on:keyup.enter="search = tmpSearch"></v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn color="primary" @click="search = tmpSearch">Rechercher</v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.date="{ item }">
              {{ item.date }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.details="{ item }">
              <v-btn elevation="0" small @click="getEmailDetail(item)" color="primary">
                Ouvrir
              </v-btn>
            </template>
          </v-data-table>
          <v-col class="pt-3 pb-2">
            <div class="text-center">
              <v-pagination circle :total-visible="20" v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-col>
        </v-card>
        <v-progress-circular style="margin-top: 30vh" v-else size="90" indeterminate
          color="primary"></v-progress-circular>
        <v-btn v-if="getItems" class="mt-1" style="width: 100%">
          <download-excel :name="'export_liste_mails'" :data="json_data">
            Exporter les données
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" style="
        position: absolute;
        background-color: rgb(255, 255, 255, 0.7);
        height: 100%;
        width: 100%;
        top: 0px;
        z-index: 3;
      " align="center">
      <v-progress-circular style="margin-top: 40vh" size="110" indeterminate color="primary"
        :width="7"></v-progress-circular>
    </div>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue-resource@1.5.3"></script>
<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import engine from "@/data/dataApi";
import axios from "axios";
import moment from 'moment'
Vue.component("downloadExcel", JsonExcel);

export default {
  data() {
    return {
      emailSendingBlueDetailData: undefined,
      emailmailShipDetailData: undefined,
      itemsPerPage: 40,
      page: 1,
      pageCount: 0,
      type: "send",
      isLoading: false,
      tmpSearch: "",
      search: "",
      stateTranslation: {
        sent: "Expédié",
        delivered: "Délivré",
        click: "Cliqué",
        open: "Ouvert",
        proxy_open: "Proxy ouvert",
      },
      headers: [
        { text: "Date", value: "date" },
        { text: "Expéditeur", value: "from" },
        { text: "Destinataire", value: "email" },
        { text: "Subject", value: "subject" },
        { text: "Details", value: "details" },
      ],
      isEmailDetailDataOpen: false,
      emailDetailData: undefined,
      emailContentSortByEmail: {},
      selectedEmail: undefined,
      emailsList: undefined,
    };
  },
  props: {
    emailList: {},
  },
  mounted() {
    if (this.emailList.length !== 0) {
      this.selectedEmail = this.emailList[0];
    }
  },
  watch: {
    tmpSearch(val) {
      if (val == null || val.length == 0) {
        this.search = val;
      }
    },
    selectedEmail(val) {
      let that = this;
      that.emailsList = undefined;
      engine.sendinBlue_get_emails_list(val, function (data) {
        let mails = data.data.data.filter((val) => that.$moment(val.fieldData.date).isAfter(that.$moment().subtract(30,'d')))
        that.emailsList = mails.map((val) => ({
          date: val.fieldData.date,
          email: val.fieldData.email,
          event: val.fieldData.event,
          from: val.fieldData.from,
          json: val.fieldData.json,
          subject: val.fieldData.subject,
          messageId: val.fieldData.messageId,
          event: val.fieldData.event,
          api_key: val.fieldData.api_key,
          tag: val.fieldData.tag,
        }));
      });
    },
  },
  methods: {
    getFormatedDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    logout(){
      this.$emit("logout",{})
    },
    filterOnlyCapsText(value, search, item) {
      if (value === undefined || value.length == 0) return false;
      if (
        value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
          )
      )
        return true;
      return false;
    },
getEmailDetail(email) {
  
this.emailSendingBlueDetailData = undefined
this.emailmailShipDetailData = undefined

if (email.tag == "SENDINBLUE")
  this.getEmailDetailSendingBlue(email)
else if (email.tag == "MAILCHIMP")
  this.getEmailDetailMailShip(email)

},
getEmailDetailMailShip(email) {
  let that = this
  this.isLoading = true
  // let mailchimpClient = require('@mailchimp/mailchimp_transactional')('md-jtcQDRriBG_otBnv7IQOdA');
  let mailchimpClient = require('@mailchimp/mailchimp_transactional')(email.api_key);
  let id = email.messageId
  //id = '20240521_04b6f9778e8a4a5a9fb698c16e7c824e'
  // id = 'b7c91e808107441ab9cfc066b92d3567'
  // id =  '004506510eb347058aa0f55da2ad134f'

  const run = async () => {
    const response2 = await mailchimpClient.messages.content({ id });

    that.emailmailShipDetailData = response2
    that.isEmailDetailDataOpen = true
    that.isLoading = false
  }
  run()
},
getEmailDetailSendingBlue(email) {
let that = this;
that.isLoading = true;
let url =
  "https://api.sendinblue.com/v3/smtp/emails?messageId=%3C" +
  email.messageId.replaceAll("<", "").replaceAll(">", "") +
  "%3E&sort=desc&limit=500&offset=0";
axios
  .get(url, {
    responseType: "json",
    credentials: false,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "api-key": email.api_key,
    },
  })
  .then((res) => {
    if (res.status == 200 && Object.keys(res.data).length > 0) {
      let data = res.data.transactionalEmails.find(
        (val) =>
          val.email == email.email &&
          val.from == email.from &&
          val.subject == email.subject
      );
      let url2 = "https://api.sendinblue.com/v3/smtp/emails/" + data.uuid;
      axios
        .get(url2, {
          responseType: "json",
          credentials: false,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "api-key": email.api_key,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            that.emailSendingBlueDetailData = res.data;
            let objectEvent = {};
            for (let i = 0; i < that.emailSendingBlueDetailData.events.length; i++) {
              if (
                objectEvent[that.emailSendingBlueDetailData.events[i].name] ==
                undefined
              ) {
                objectEvent[that.emailSendingBlueDetailData.events[i].name] = [];
              }
              objectEvent[that.emailSendingBlueDetailData.events[i].name].push(
                that.emailSendingBlueDetailData.events[i].time
              );
            }
            that.emailSendingBlueDetailData.events = objectEvent;
            that.isEmailDetailDataOpen = true;
          }
          that.isLoading = false;
        });
    } else {
      that.isLoading = false;
    }
  });
},
  },
  computed: {
    emailsListFiltered() {
      if (!this.emailsList) return [];
      if (this.type === "send") {
        let tmpEmails = this.emailsList.filter((val) => val.from === this.selectedEmail)
         tmpEmails = tmpEmails.map((val) => ({
            // date: (val.json && val.json.length > 0)?this.$moment(JSON.parse(val.json).date).format(
            //   "DD/MM/YYYY HH:mm:ss"
            // ):"",
            email: val.email,
            from: val.from,
            subject: val.subject,
            messageId: val.messageId,
            api_key:val.api_key,
            tag: val.tag,
          }));

          return tmpEmails
      } else {
        let tmpEmails = this.emailsList
        .filter((val) => val.email === this.selectedEmail)
        tmpEmails = tmpEmails.map((val) => ({
            // date:(val.json && val.json.length > 0)? this.$moment(JSON.parse(val.json).date).format(
            //   "DD/MM/YYYY HH:mm:ss"
            // ):"",
            email: val.email,
            from: val.from,
            subject: val.subject,
            messageId: val.messageId,
            api_key:val.api_key,
            tag: val.tag,
          }));

          return tmpEmails
      }
    },
    json_data() {
      let json = [];
      if (this.getItems && this.getItems.length != 0)
        for (let i = 0; i < this.getItems.length; i++) {
          json.push({
            Date: this.getItems[i].date,
            Expéditeur: this.getItems[i].from,
            Destinataire: this.getItems[i].email,
            Subject: this.getItems[i].subject,
          });
        }
      return json;
    },
    getItems() {
      return this.emailContentSortByEmail[this.selectedEmail];
    },
  },
};
</script>

<style></style>