<template>
  <v-app>
    <v-main style="background-color:#E0E0E0">
      <login-page/>
    </v-main>
  </v-app>
</template>

<script>
import loginPage from './components/page-login.vue';

export default {
  name: 'App',

  components: {
    loginPage,
  },

  data: () => ({
    //
  }),
};
</script>
